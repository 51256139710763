.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchForm {
  margin: 12px auto 8px auto;
  display: flex;
  color: #1a1a1a;
  height: 64px;
  justify-content: center;
  max-width: 724px;

  @media (--viewportMedium) {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    height: 48px;
  }
}

.searchInput {
  width: 454px;
  font-size: 16px;
  font-weight: 500;
  color: #4A4A4A;

  @media (max-width: 768px) {
    width: 100%;
  }

  &::placeholder {
    color: #4A4A4A;
  }
}

.labelSuggestions {
  font-size: 12px;
  font-weight: 500;
  color: #F8F8F8;
}

.submitButton {
  width: 260px;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    min-height: 48px;
    font-size: 14px;
  }
}

.suggestionsBlock {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.suggestions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 10px 0;
}

.suggestionsButton {
  border: 1px solid #FFFFFF;
  background: rgba(255, 255, 255, 0.2);
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 37px;
  font-size: 14px;
  opacity: 70%;

  &:hover {
    background-color: transparent;
    opacity: inherit;
  }
}

@media (max-width: 768px) {
  .suggestions {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  .suggestionsButton {
    font-size: 12px; 
  }
}

.submitButtonArrow {
  margin-left: 10px;
  fill: white;
  stroke: white;
}
