.root {
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  transition: var(--transitionStyleButton);
}

.media.clickable::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 4px;
  background-color: var(--marketplaceColor);
  transform: translateY(100%);
  transition: transform var(--transitionStyleButton);
}

.media.clickable:hover {
  border-radius: 8px 8px 0 0;
  transform: scale(0.98);
}

.media.clickable:hover::after {
  transform: translateY(0);
}

.text {
  margin-top: 20px;
}

.text a {
  color: var(--marketplaceColor);
  text-decoration: none;
  transition: var(--transitionStyleButton);
}

.text a:hover {
  text-decoration: underline;
}

.separateLink {
  display: block;
  margin-top: 20px;
}
